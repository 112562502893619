<template>
    <b-modal id="print-transportation" title="Print Transportation" ref="modal" ok-title="Download" @ok="onDownload"
        @show="onClose" :no-close-on-backdrop="true">

        <div class="text-center">
            <canvas id="canvas" :height="frameHeight" :width="frameWidth" />
        </div>

    </b-modal>
</template>

<script>

// Util
import { loadText, loadImages } from '@/utils/canvasUtil'

// QR Codes
import QRCode from 'qrcode';
import QrCodeBG from '../../../../public/img/qrcode/qrcode_bg.png';

// Others
import EventBus from '@/shared/event-bus';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'print-transportation',
    data() {
        return {

            frameHeight: 400,
            frameWidth: 224,
            qrCodeheight: 195,
            qrCodeWidth: 195,

            foregroundColor: '#000000',
            backgroundColor: '#ffffff',
            labelColor: '#9BAEBC',

            size: 24,
            font: 'arial',
            baseline: 'middle',
            angle: 0,
            isTransparent: false,
            user: {},
            dataUrl: '',

            plateNo: '',
            company: '',
            qrCodeData: '',
            qrCodeDataUrl: '',
        };
    },
    computed: {
    },
    mounted() {
        EventBus.$on('onPrintTransportation', (selTransportation) => {
            this.plateNo = selTransportation.plateNo;
            this.company = selTransportation.company;
            this.qrCodeData = this.plateNo + ' - ' + this.company;

            setTimeout(() => { this.draw(); }, 0);
        });
    },
    methods: {
        async draw() {
            const canvas = document.getElementById('canvas');
            const context = canvas.getContext('2d');

            const qrcode = await QRCode.toDataURL(this.qrCodeData);

            const images = {
                img1: QrCodeBG,
                img2: qrcode
            };

            let texts = [
                {
                    label: {
                        title: 'Plate No.', x: 35, y: 260, w: 210, h: 10, c: this.labelColor
                    },
                    value: {
                        title: this.plateNo, x: 115, y: 260, w: 80, h: 10, c: this.foregroundColor
                    }
                }, {
                    label: {
                        title: 'Company', x: 35, y: 280, w: 210, h: 10, c: this.labelColor
                    },
                    value: {
                        title: this.company, x: 115, y: 280, w: 80, h: 10, c: this.foregroundColor
                    }
                }
            ];


            loadImages(images, image => {
                context.drawImage(image.img1, 0, 0, 224, 400);
                context.drawImage(image.img2, 14, 28, this.qrCodeWidth, this.qrCodeheight);

                let linesAdded = 0;
                let valueY = 0;
                let labelY = 0;

                texts.forEach((text, index) => {
                    if (index === 0) {
                        valueY = text.value.y;
                        labelY = text.label.y;
                    } else {
                        valueY += 15;
                        labelY += 15;
                    }

                    if (linesAdded > 0) {
                        valueY += linesAdded * 11;
                        labelY += linesAdded * 11;
                        linesAdded = 0; // Reset lines to be added to 0
                    }

                    linesAdded = loadText(
                        context,
                        text.value.title,
                        text.value.x,
                        valueY,
                        text.value.w,
                        text.value.h,
                        text.value.c
                    );

                    loadText(
                        context,
                        text.label.title,
                        text.label.x,
                        labelY,
                        text.label.w,
                        text.label.h,
                        text.label.c
                    );
                });

                context.restore();

                this.qrCodeDataUrl = document.getElementById('canvas').toDataURL('image/png');
            });
        },

        async onDownload(evt) {
            evt.preventDefault();

            const url = this.qrCodeDataUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${_.toLower(this.qrCodeData)}.png`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);

        },
        onClose() {
            this.resetComponent();
        },
        resetComponent() {
            const canvas = document.getElementById('canvas');
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);

            this.plateNo = '';
            this.company = '';
            this.qrCodeData = '';
        },
    },
    beforeDestroy() {
        EventBus.$off('onPrintTransportation');
    },
};
</script>