<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="mb-2 details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
                    loggedUser.id }}</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(dateUpdated)="row">
                {{ getDateUpdated(row) }}
            </template>
            <template v-slot:cell(changes)="row">
                <div class="changed-section" v-if="hasValueChanged(row.item, 'company')">
                    <b>Company</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'company') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'company') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'hasCheckingInterval')">
                    <b>Has Checking Interval?</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'hasCheckingInterval') === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'hasCheckingInterval') === true ? 'YES' : 'NO' }}
                    </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'checkingInterval')">
                    <b>Checking Interval (hrs)</b>
                    <br>
                    From <b class="old-value numFont"> {{ getOldValue(row.item, 'checkingInterval') }} </b>
                    to <b class="new-value numFont"> {{ getNewValue(row.item, 'checkingInterval') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'description')">
                    <b>Description</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'description') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'description') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'isActive')">
                    <b>Status</b>
                    <br>
                    From <span v-if="row.item.old !== null">
                        <TransportationStatus :transportation="row.item.old" />
                    </span>
                    <span v-else>&nbsp;-&nbsp;</span>
                    to
                    <TransportationStatus :transportation="row.item.new" />
                </div>
            </template>
            <template v-slot:cell(updatedBy)="row">{{ getUpdatedBy(row) }}</template>
        </b-table>
    </div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';

// Components
import TransportationStatus from '@/views/setup/transportation/TransportationStatus';

// DAO
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Others
import _ from 'lodash';

export default {
    name: 'transportation-change-logs-details-view',
    components: {
        TransportationStatus
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            transportationId: '',

            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        }
    },
    watch: {
        transportationId: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    async mounted() {
        this.transportationId = this.row.item.id;
        await this.retrieveChangeLog(this.transportationId);
    },
    methods: {
        getDateUpdated(log) {
            let dateUpdated = log.item.new.dateUpdated !== null ? log.item.new.dateUpdated : log.item.new.dateCreated;
            return this.getFormattedDateWithTime(dateUpdated);
        },
        getUpdatedBy(log) {
            return log.item.new.updatedBy !== null ? log.item.new.updatedBy : log.item.new.createdBy;
        },
        async retrieveChangeLog(transportationId) {
            if (transportationId) {
                let param = {
                    collection: 'transportations',
                    id: transportationId,
                    userId: !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },
        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, log => {
                const oldLog = log.old ? log.old : {};
                const newLog = log.new ? log.new : {};
                // Company
                const oldCompany = oldLog.company ? oldLog.company : '';
                const newCompany = newLog.company ? newLog.company : '';
                // Description
                const oldDesc = oldLog.description ? oldLog.description : '';
                const newDesc = newLog.description ? newLog.description : '';
                // Checking Interval
                const oldHasCheckingInterval = oldLog.hasCheckingInterval ? oldLog.hasCheckingInterval : '';
                const newHasCheckingInterval = newLog.hasCheckingInterval ? newLog.hasCheckingInterval : '';
                // Checking Interval
                const oldCheckingInterval = oldLog.checkingInterval ? oldLog.checkingInterval : '';
                const newCheckingInterval = newLog.checkingInterval ? newLog.checkingInterval : '';
                // Status
                const oldStatus = oldLog.isActive ? oldLog.isActive : 'false';
                const newStatus = newLog.isActive ? newLog.isActive : 'false';

                return oldCompany !== newCompany ||
                    oldDesc !== newDesc ||
                    oldHasCheckingInterval !== newHasCheckingInterval ||
                    oldCheckingInterval !== newCheckingInterval ||
                    oldStatus !== newStatus;
            });
            this.changeLogs = filteredLogs;
        },
        hasValueChanged(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let value = oldLog[fieldName] ? oldLog[fieldName] : "-";
            return value;
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};
            let value = newLog[fieldName] ? newLog[fieldName] : "-";
            return value;
        },
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}
</style>